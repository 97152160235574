import React, { useEffect, useContext } from 'react';
import { AuthContext } from '~contexts/auth';

import '../style.scss';

const Logout = () => {
  const { signOut } = useContext(AuthContext);

  useEffect(() => {
    signOut();
  }, []);

  return null;
};

export default Logout;

import React from 'react';
import { useIntl } from 'react-intl';

import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';

import '../../style.scss';

const VerifyRequest = () => {
  const intl = useIntl();
  useLayout({ displayCities: false });
  useSeo(
    intl.formatMessage({
      id: 'containers.authentication.verify.request.seo.title',
    })
  );

  return (
    <section className="c-authentication l-container">
      <div className="c-authentication__content">
        <h1 className="l-headline--secondary">
          {intl.formatMessage({
            id: 'containers.authentication.verify.request.headline',
            defaultMessage: 'Please check your email',
          })}
        </h1>

        <div className="c-authentication__intro">
          {intl.formatMessage({
            id: 'containers.authentication.verify.request.intro',
            defaultMessage: 'We have sent you an email with verification link.',
          })}
        </div>
      </div>
    </section>
  );
};

export default VerifyRequest;
